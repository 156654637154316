import React from 'react'
import axios from 'axios'
import {API, BEARER} from '../config'
import {useAuth} from '../hook/useAuth'
import {useDispatch} from 'react-redux'
import {turnMaintainModeOn} from '../store/maintainSlice'


const AxiosDefaults = () => {

    const {token, logOut} = useAuth()
    const dispatch = useDispatch()

    axios.defaults.baseURL = API
    if (token) {
        axios.defaults.headers.common['Authorization'] = `${BEARER} ${token}`
    } else {
        delete axios.defaults.headers.common['Authorization']
    }


    axios.interceptors.response.use(
        response => response,
        error => {

            console.log(`AXIOS ERROR ${error?.code}`, error)

            if (error?.response?.status === 401) {
                logOut()
            }

            if (error?.code === 'ERR_NETWORK') {
                dispatch(turnMaintainModeOn())
            }

            return Promise.reject(error)

            // if (error.code === "ERR_CANCELED") {
            //     // aborted in useEffect cleanup
            //     return Promise.resolve({status: 499})
            // }

            // return Promise.reject((error.response && error.response.data) || 'Error')
        }
    )


    return null
}

export default React.memo(AxiosDefaults)
