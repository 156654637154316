import React, {useMemo} from 'react'
import {GraphQLClient} from 'graphql-request'
import {BEARER, GRAPHQL_ENDPOINT} from '../config'
import {GraphQLContext} from '../context/GraphQLContext'
import {useAuth} from '../hook/useAuth'


const GraphQLProvider = ({children}) => {

    //console.log('GraphQLProvider rendered', performance.now())

    const {token, logOut} = useAuth()

    const graphQLClient = useMemo(() => new GraphQLClient(GRAPHQL_ENDPOINT, {
        headers: token ? {
            Authorization: `${BEARER} ${token}`
        } : {},
        errorPolicy: 'ignore',
        responseMiddleware: response => {
            // console.log('GRAPHQL ERROR', response?.response?.error)
            if (response?.response?.error?.status === 401) {
                console.log('GRAPHQL ERROR 401')
                logOut()
            }
        }
    }), [token, logOut])


    return (
        <GraphQLContext.Provider value={graphQLClient}>
            {children}
        </GraphQLContext.Provider>
    )
}

export default React.memo(GraphQLProvider)
