import {createSlice} from '@reduxjs/toolkit';


const rightMenuSlice = createSlice({
    name: 'rightMenu',
    initialState: {
        show: false,
        name: ''
    },
    reducers: {
        showRightMenu: (state, action) => {
            state.show = true
        },
        hideRightMenu: (state, action) => {
            state.show = false
        },
        toggleRightMenu: (state, action) => {
            state.show = !state.show
        },
        setRightMenuName: (state, action) => {
            state.name = action.payload
        }
    }
})

export const {showRightMenu, hideRightMenu, toggleRightMenu, setRightMenuName} = rightMenuSlice.actions;

export default rightMenuSlice.reducer
