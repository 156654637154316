import {createSlice} from '@reduxjs/toolkit';


const dealTypeSlice = createSlice({
    name: 'dealType',
    initialState: {
        dealType: undefined
    },
    reducers: {
        setDealType: (state, action) => {
            state.dealType = action.payload
        }
    }
})

export const {setDealType} = dealTypeSlice.actions;

export default dealTypeSlice.reducer
