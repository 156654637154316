import {configureStore} from '@reduxjs/toolkit'
import modalReducer from './modalSlice'
import maintainReducer from './maintainSlice'
import rightMenuReducer from './rightMenuSlice'
import customerModeReducer from './customerModeSlice'
import dealTypeReducer from './dealTypeSlice'
import wishHousesReducer from './wishHousesSlice'


export default configureStore({
    reducer: {
        modal: modalReducer,
        rightMenu: rightMenuReducer,
        customerMode: customerModeReducer,
        dealType: dealTypeReducer,
        maintain: maintainReducer,
        wishHouses: wishHousesReducer
    }
})
