import React from 'react'


const ErrorBoundaryMessage = () => {
    return <>
        <div className="container d-flex flex-column justify-content-center min-vh-100">
            <div className="text-center pt-4 mt-lg-2 mb-7">
                <h1 className="display-5">Щось пішло не так</h1>
                <p className="fs-lg pb-2 pb-md-0 mb-4 mb-md-5">Будь ласка, перезавантажте сторінку або спробуйте пізніше</p>
                <button className="btn btn-success" onClick={() => {
                    window.location.reload()
                }}>
                    Оновити сторінку
                </button>
            </div>
            <div style={{height: '60px'}}></div>
        </div>
    </>
}

export default React.memo(ErrorBoundaryMessage)
