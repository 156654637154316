import {createSlice} from '@reduxjs/toolkit'
import {parseJSON} from '../utilities/functions'

const WISH_HOUSES_LABEL = 'wishHouses'

const wishHousesSlice = createSlice({
    name: 'wishHouses',
    initialState: {
        houses: (parseJSON(localStorage.getItem(WISH_HOUSES_LABEL)) || []).filter(Boolean)
    },
    reducers: {
        pickWishHouse: (state, action) => {
            const houseId = action.payload
            state.houses = state.houses.includes(houseId) ?
                state.houses.filter(item => item !== houseId) :
                [...state.houses, houseId]
            localStorage.setItem(WISH_HOUSES_LABEL, JSON.stringify(state.houses))
        },
        setWishHouses: (state, action) => {
            state.houses = action.payload
            localStorage.setItem(WISH_HOUSES_LABEL, JSON.stringify(state.houses))
        },
        clearWishHouses: (state, action) => {
            state.houses = []
            localStorage.setItem(WISH_HOUSES_LABEL, JSON.stringify(state.houses))
        }
    }
})

export const {pickWishHouse, setWishHouses, clearWishHouses} = wishHousesSlice.actions

export default wishHousesSlice.reducer
