import {createSlice} from '@reduxjs/toolkit';
import {parseJSON} from '../utilities/functions'


const customerModeSlice = createSlice({
    name: 'customerMode',
    initialState: {
        isCustomerMode: parseJSON(localStorage.getItem('isCustomerMode')) || false
    },
    reducers: {
        customerModeToggle: (state, action) => {
            localStorage.setItem('isCustomerMode', JSON.stringify(!state.isCustomerMode))
            state.isCustomerMode = !state.isCustomerMode
        }
    }
})

export const {customerModeToggle} = customerModeSlice.actions

export default customerModeSlice.reducer
