import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {BACKEND_URL} from '../config'
import isOnline from 'is-online'
import isReachable from 'is-reachable'


export const turnMaintainModeOn = createAsyncThunk(
    'maintain/turnMaintainModeOn',
    async () => {
        const online = await isOnline()
        // console.log('online', online)
        const isBackendAvailable = await isReachable(BACKEND_URL)
        // console.log('isBackendAvailable', isBackendAvailable)
        return online && !isBackendAvailable
    }
)


const maintainSlice = createSlice({
    name: 'maintain',
    initialState: {
        value: false
    },
    reducers: {
        //turnMaintainModeOn: (state, action) => {
        //    state.value = true
        //},
        turnMaintainModeOff: (state, action) => {
            state.value = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(turnMaintainModeOn.fulfilled, (state, action) => {
            const turnOn = action.payload
            if (turnOn) {
                state.value = true
            }
        })
    }
})

export const {turnMaintainModeOff} = maintainSlice.actions;

export default maintainSlice.reducer
