import React from 'react';
import './LoaderDots.scss'


function LoaderDots() {
    return (
        <div className="dots-container">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    )
}

export default React.memo(LoaderDots)
