import React, {useEffect, useState} from 'react'
import {OnlineStatusContext} from '../context/OnlineStatusContext'


const OnlineStatusProvider = ({children}) => {

    const [onlineStatus, setOnlineStatus] = useState(true)


    useEffect(() => {
        window.addEventListener('offline', () => {
            setOnlineStatus(false)
        })
        window.addEventListener('online', () => {
            setOnlineStatus(true)
        })

        return () => {
            window.removeEventListener('offline', () => {
                setOnlineStatus(false)
            })
            window.removeEventListener('online', () => {
                setOnlineStatus(true)
            })
        }
    }, [])

    return (
        <OnlineStatusContext.Provider value={onlineStatus}>
            {children}
        </OnlineStatusContext.Provider>
    )
}

export default React.memo(OnlineStatusProvider)
