import {createContext} from "react";

export const AuthContext = createContext({
    user: undefined,
    token: undefined,
    isLoading: false,
    isError: false,
    isAuthorized: false,
    error: undefined,
    setUser: () => {},
    setToken: () => {},
    logOut: () => {}
})
