import isEqual from 'lodash-es/isEqual'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'


export const parseJSON = json => {
    try {
        return JSON.parse(json)
    } catch (error) {
        // console.error('JSON parsing error:', error)
        return null
    }
}


export const math = {
    round: function (arg, r) {
        const pow = Math.pow(10, r || 0)
        return Math.round(arg * pow) / pow
    },
    floor: function (arg, r) {
        const pow = Math.pow(10, r || 0)
        return Math.floor(arg * pow) / pow
    },
    ceil: function (arg, r) {
        const pow = Math.pow(10, r || 0)
        return Math.ceil(arg * pow) / pow
    }
}


// Declension
export function getDeclension(number, one, two, five) {
    if (!number && number !== 0) return ''
    let n = Math.abs(number)
    n %= 100
    if (n >= 5 && n <= 20) {
        return five
    }
    n %= 10
    if (n === 1) {
        return one
    }
    if (n >= 2 && n <= 4) {
        return two
    }
    return five
}


export const compareStates = (value, initValue) => {
    if (!isEqual(value, initValue)) return value
}


// export function generateTimeArray(startTime, endTime, interval) { // 'HH:mm', 'HH:mm', 'HH:mm'
//     const timeArray = []
//     const start = moment(startTime, 'HH:mm')
//     const end = moment(endTime, 'HH:mm')
//     const intervalDuration = moment.duration(interval)
//     for (let current = moment(start);
//          current <= end;
//          current.add(intervalDuration)
//     ) {
//         timeArray.push(current.format('HH:mm'))
//     }
//     return timeArray
// }

export function generateTimeArray(startTime, endTime, intervalMinutes) { // 'HH:mm', 'HH:mm', 30
    dayjs.extend(customParseFormat)
    const $start = dayjs(startTime, 'HH:mm')
    const $end = dayjs(endTime, 'HH:mm')
    let $currentTime = $start
    const timesArray = []
    while ($currentTime.isBefore($end) || $currentTime.isSame($end)) {
        timesArray.push($currentTime.format('HH:mm'))
        $currentTime = $currentTime.add(intervalMinutes, 'minute')
    }
    return timesArray
}



export function generateAddress(street, streetType, build, apt, city, district, region) {
    const district_ = district && `${district} р-н`
    const region_ = district_ && region && `${region} обл.`
    const street_ = street && [streetType, street].filter(item => item).join(' ')
    const build_ = build && street_ && build
    const apt_ = apt && build_ && `кв. ${apt}`

    return [street_, build_, apt_, city, district_, region_].filter(item => item).join(', ')
}


export const notEmptyArr = arr => Array.isArray(arr) && arr.length > 0


export const formatNumberToShorthand = val => {

    // Thousands, millions, billions etc.
    let s = ['', 'k', 'm', 'b', 't']

    // Dividing the value by 3
    let sNum = Math.floor(('' + val).length / 3)

    // Calculating the precised value
    let sVal = parseFloat(
        (sNum !== 0 ? val / Math.pow(1000, sNum) : val).toPrecision(2)
    )

    if (sVal % 1 !== 0) {
        sVal = sVal.toFixed(1)
    }

    // Appending the letter to precise val
    return sVal + s[sNum]
}


export function checkHostAvailabilityPromiseXhr(host) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest()
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve()
                } else {
                    reject()
                }
            }
        }
        xhr.open('GET', host, true)
        xhr.send()
    })
}


export const checkHostAvailabilityPromiseFetch = (hostToCheck) => {
    return new Promise(async (resolve) => {
        try {
            const response = await fetch(hostToCheck)
            if (response.ok) {
                resolve(true)
            } else {
                resolve(false)
            }
        } catch (error) {
            resolve(false)
        }
    })
}


// Если у объекта есть свойства, и они все пустые
export const areAllPropertiesEmpty = (obj) => {
    if (obj === null || typeof obj !== 'object') return false
    let values = Object.values(obj)
    if (values.length === 0) return false
    return values.every(value => value === undefined)
}


export function formatPhoneNumber(phoneNumber) {
    let match = phoneNumber?.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{2})(\d{2})$/)
    if (!match) return null
    return `${match[1]}-${match[2]}-${match[3]}-${match[4]}`
}


// Вернет true, если клик пришелся по элементу с selector
export const checkClickPath = (event, selector) => {
    let currentElement = event.target
    while (currentElement) {
        if (currentElement.matches(selector)) {
            return true
        }
        if (currentElement === event.currentTarget) {
            break
        }
        currentElement = currentElement.parentElement
    }

    return false
}


export const generateFullCityName = (city) => {
    const cityType = city?.attributes?.type?.data?.attributes?.name
    const cityName = city?.attributes?.name
    const cityAltName = city?.attributes?.altName
    const cityFullName = cityAltName ? `(${cityAltName})` : ''
    const cityFull = [cityType, cityName, cityFullName].filter(Boolean).join(' ')
    const districtName = city?.attributes?.district?.data?.attributes?.name
    const districtFull = districtName ? `${districtName} р-н` : ''
    const regionName = city?.attributes?.region?.data?.attributes?.name
    const regionFull = regionName ? `${regionName} обл.` : ''
    return [cityFull, districtFull, regionFull].filter(Boolean).join(', ')
}

export const generateFullDistrictName = (district) => {
    const districtName = district?.attributes?.name
    const districtFull = districtName ? `${districtName} р-н` : ''
    const regionName = district?.attributes?.region?.data?.attributes?.name
    const regionFull = regionName ? `${regionName} обл.` : ''
    return [districtFull, regionFull].filter(Boolean).join(', ')
}

export const generateFullRegionName = (region) => {
    const regionName = region?.attributes?.name
    return regionName ? `${regionName} обл.` : ''
}


export const shouldBeEqual = (date1, date2) => {
    console.log(date1, date2)
    if (!isEqual(date1, date2)) console.error(`date1 ${date1} and date2 ${date2} are not equal`)
}


