import React, {useCallback, useMemo} from 'react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {useDispatch} from "react-redux";
import {turnMaintainModeOn} from "../store/maintainSlice";
import {useAuth} from '../hook/useAuth';
import {useLocation, useNavigate} from 'react-router-dom';






const TanstackQueryProvider = ({children}) => {

    // const {logOut} = useAuth()

    const onError = error => {
        console.log('TANSTACK ERROR', error)
        // Unauthorized
        // if (error?.response?.status === 401) {
        //     console.log('TanstackQueryProvider caught 401')
            // logOut()
        // }
    }


    const queryClient = useMemo(() => new QueryClient({
        defaultOptions: {
            queries: {
                networkMode: 'offlineFirst',
                refetchOnWindowFocus: false,
                // refetchOnReconnect: false, // default true
                // refetchOnMount: false, // default true
                retry: 1,
                staleTime: 10000,
                onError
            },
            mutations: {
                networkMode: 'offlineFirst',
                onError
            }
        }
    }), [])

    // const queryClient = new QueryClient({
    //     defaultOptions: {
    //         queries: {
    //             networkMode: 'offlineFirst',
    //             refetchOnWindowFocus: false,
    //             // refetchOnReconnect: false,
    //             // refetchOnMount: false,
    //             retry: 1,
    //             staleTime: 10000,
    //             // onError: err => {
    //             //     console.log(err)
    //             //     dispatch(turnMaintainModeOn())
    //             // }
    //         },
    //         mutations: {
    //             networkMode: 'offlineFirst'
    //         }
    //     }
    // })


    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    )

}

export default React.memo(TanstackQueryProvider)
