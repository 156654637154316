import React from 'react'
import {createRoot} from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import store from './store/store'
import App from './App'
import GraphQLProvider from './provider/GraphQLProvider'
import TanstackQueryProvider from './provider/TanstackQueryProvider'
import AuthProvider from './provider/AuthProvider'
import AxiosDefaults from './hoc/AxiosDefaults'
import OnlineStatusProvider from './provider/OnlineStatusProvider'
import ErrorBoundaryMessage from './components/Plugs/ErrorBoundaryMessage'
import {ErrorBoundary} from 'react-error-boundary'


// import 'flatpickr/dist/flatpickr.min.css' // todo попробовать стили календаря всунуть в конкретные компоненты и посмотреть, увеличится ли от этого размер билда
// import './css/flatpickr.css'
//
// import 'react-image-gallery/styles/css/image-gallery.css'
// import './css/image-gallery.scss'
//
// import './css/Around/scss/theme.scss'


const root = createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <OnlineStatusProvider>
                <TanstackQueryProvider>
                    <AuthProvider>
                        <AxiosDefaults/>
                        <GraphQLProvider>
                            <ErrorBoundary fallback={<ErrorBoundaryMessage/>}>
                                <App/>
                            </ErrorBoundary>
                        </GraphQLProvider>
                    </AuthProvider>
                </TanstackQueryProvider>
            </OnlineStatusProvider>
        </Provider>
    </BrowserRouter>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
